<template>
  <teleport to="#topbar-navigation-dropdown">
    <div
      class="navigation-dropdown"
      :class="{
        'navigation-dropdown--open': isOpen,
        'navigation-dropdown--monochromatic': isMonochromatic,
      }"
      v-click-outside="closeDropdown"
    >
      <Btn
        class="btn-toggle-navigation-panel"
        :class="`btn-toggle-navigation-panel--${btnToggleColor}`"
        icon="caret-down"
        icon-after
        @click="toggleDropdown"
        :text="btnToggleText"
        block
      />

      <nav class="navigation-dropdown-nav">
        <ul class="menu">
          <!-- <slot /> -->
          <MenuItem
            v-for="item in itemsData"
            :key="item.key"
            :class="`menu-item--${!isMonochromatic ? item.key : 'monochromatic'}`"
            :item-data="{
              label: item.label,
              iconName: !isMonochromatic ? item.iconName : '',
              route: item.route,
            }"
            @click="onItemClick(item)"
          />
        </ul>
      </nav>
    </div>
  </teleport>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import MenuItem from '@/components/layout/MenuItem.vue'

export default {
  name: 'NavigationDropdown',

  components: {
    Btn,
    MenuItem,
  },

  props: {
    itemsData: {
      type: Object,
      required: true,
    },
    isMonochromatic: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      btnToggleText: '',
      btnToggleColor: '',
    }
  },

  mounted() {
    const filterItem = this.itemsData.findIndex(
      (item) => item.route.name === this.$route.name
        || (item.route.contains && this.$route.name.includes(item.route.contains)),
    )
    this.onItemClick(this.itemsData[filterItem !== -1 ? filterItem : 0])
  },

  methods: {
    toggleDropdown() {
      if (!this.isOpen) {
        // Opening dropdown
        this.isOpen = true
      } else {
        // Closing dropdown
        this.isOpen = false
      }
    },
    closeDropdown() {
      this.isOpen = false
    },
    onItemClick(item) {
      this.btnToggleText = item.label
      this.btnToggleColor = item.key
      this.closeDropdown()
    },
  },
}
</script>

<style lang="scss" scoped>
// TOOL MENU BUTTON ("kefta")

.navigation-dropdown {
  position: relative;
  width: 22rem;
}

.btn-toggle-navigation-panel {
  justify-content: space-between;
  color: white !important;

  :deep(> .icon) {
    transition: all 0.25s;
  }

  // Active button
  .navigation-dropdown--open & {
    :deep(> .icon) {
      transform: rotateZ(180deg);
    }

    &::before {
      background-color: rgba(white, 0.25);
    }
  }
}

.btn-toggle-navigation-panel--protocol-details {
  background-color: $color-protocol-details;
}
.btn-toggle-navigation-panel--protocol-versions {
  background-color: $color-protocol-versions;
}
.btn-toggle-navigation-panel--protocol-sharing {
  background-color: $color-protocol-sharing;
}
.btn-toggle-navigation-panel--protocol-files {
  background-color: $color-protocol-files;
}
.btn-toggle-navigation-panel--essai-generalinformation {
  background-color: $color-protocol-details;
}

.btn-toggle-navigation-panel--protocol-grouping {
  background-color: $color-protocol-grouping;
}

.btn-toggle-navigation-panel--color-1 {
  background-color: $color-navdropdown-1;
}
.btn-toggle-navigation-panel--color-2 {
  background-color: $color-navdropdown-2;
}
.btn-toggle-navigation-panel--color-3 {
  background-color: $color-navdropdown-3;
}
.btn-toggle-navigation-panel--color-4 {
  background-color:$color-navdropdown-4;
}
.btn-toggle-navigation-panel--color-5 {
  background-color: $color-navdropdown-5;
}
.btn-toggle-navigation-panel--color-6 {
  background-color: $color-navdropdown-6;
}

.navigation-dropdown-nav {
  overflow: hidden;
  position: absolute;
  width: 22rem;
  left: 0;
  top: 4rem + $gutter-half;
  border-radius: $border-radius-base;

  transform: translateY(-$gutter-double);
  opacity: 0;
  pointer-events: none; // Let events pass through
  transition: all 0.25s $ease-in-out;

  background-color: white;
  @include shadow(3);
  z-index: +10;

  .navigation-dropdown--open & {
    transform: translateY(0);
    opacity: 1;
    pointer-events: initial;
  }
}

.navigation-dropdown--monochromatic {

}
</style>
